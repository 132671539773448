.hidden {
    display: none !important;
}

body {
    font-weight: 400 !important;
}

.none-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading-container {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
}

.loading-table {
    height: 78vh;
    display: block;
}
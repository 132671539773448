$lightgray: #F1F1F1;

.file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    .input {
        display: none;
    }

    .file-upload {
        height: 20rem;
        // width: 20rem;
        border: 1px solid #dee2e6;
        // box-shadow: $box-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // background: $lightgray;
        text-align: center;
        transition: all 0.2s ease-in-out;
        border-radius: 0.75rem;

        &:hover {
            box-shadow: $box-shadow;
            border: 1px solid black;
        }

        &-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            i {
                font-size: 40px;
                margin-bottom: 1rem;
            }
        }

        &-template {
            max-width: 20rem;
            text-align: center;
            display: flex;
            flex-direction: column;
        }

    }

    label {
        width: 95%;
    }
}
$border-color: #c4c8cc;

.table {

    tr:hover {
        .action-button {
            visibility: initial !important;
        }
    }

    th {
        border-top: none !important;
    }

    tbody {
        tr {
            &:hover {
                background: #bdd8da75
            }
        }
    }

    .action-buttons-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 4rem;

        .action-button {
            visibility: hidden;

            &-0:hover {
                color: $success;
            }

            &-1:hover {
                color: $warning;
            }

            &-2:hover {
                color: $error;
            }
        }
    }


}

.guarantee-table {
    .advanced-table-scroll-container {
        height: 76vh !important;
        overflow-y: scroll;
        // padding: 0 1rem;
    }
}

.unallocated-table {
    .advanced-table-scroll-container {
        height: 84vh !important;
        overflow-y: scroll;
        padding: 0 1rem;
    }
}


.advanced-table {

    &-scroll-container {
        height: calc(100vh - 15rem);
        overflow-y: auto;

        & .btn:hover {
            color: $green !important;

        }

        .fa-info-circle {
            color: $success;
        }

        .fa-edit {
            color: $warning;
        }

        .fa-times-circle {
            color: $error;
        }
    }

    .action-column {
        width: 7rem;
    }

    .ui5-table-row-popin-title {
        font-weight: 800 !important;

    }




    --sapList_HeaderBackground: transparent !important;
    --sapList_Background: transparent !important;
    --sapContent_LabelColor: black;
    --sapList_BorderColor: #dee2e6 !important;
    // --sapList_BorderColor:#bdd8da;


}


ui5-table-row::part(row) {
    // background-color: red!important;
    border-top: 1px solid $border-color;
    font-weight: 300;

    &:hover {
        background: #bdd8da75
    }
}

ui5-table-cell::part(cell) {
    // background-color: red!important;
    // border-top: 2px solid #dee2e6;
    font-weight: 300 !important;
    color: black;
}

.advanced-table {
    ui5-table-column::part(column) {
        font-weight: 700 !important;
        border-bottom: 2px solid $border-color;
        width: 15%
    }
}
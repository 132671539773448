body {
    background-color: $dark-green !important;
    margin: 0;
    padding: 0;
}

.basic-layout {
    .header {
        background: white;

    }

    .content-area {
        background: #e5e8eb !important;
        height: calc(100% - 7rem) !important;
    }
}